"use client";

import { useEffect, useState } from "react";
import "../../utils/common/charts";
import Container from "@/components/common/container";
import { ROUTE_LABELS, welcomeText } from "@/utils/common/translations";
import { GetLoginLocation } from "@/utils/common/login";
import { useRouter } from "next/navigation";
import { useAuthContext } from "@/contexts/common/AuthContextProvider";
import DashboardContent from "./calls/DashboardContent";
import clsx from "clsx";
import { DashboardData } from "@/types/dashboard";
import { apiLive } from "@/utils/common/api";
import { useAlert } from "@/hooks/useAlert";

export default function Home() {
  const router = useRouter();
  const { user, getToken } = useAuthContext();
  const { setIsLoading } = useAlert();

  const [data, setData] = useState<DashboardData>({
    metrics: {
      salesCalls: null,
      supportCalls: null,
      totalCalls: null,
    },
    data: null,
    settings: {
      gailNumber: null,
    },
    pagination: {
      page: 0,
      total: 0,
      size: 20,
      pages: 0,
    },
  });

  useEffect(() => {
    const location = GetLoginLocation();

    if (location) {
      router.push(location);
      return;
    } else if (window.location.pathname === "/") {
      router.push("/campaigns");
    }
  }, [router, user]);

  const fetchData = async (pageNumber: number) => {
    setIsLoading(true, "Loading Dashboard");

    const response = await apiLive<DashboardData>(
      getToken,
      `/api/dashboard?page=${pageNumber}`
    );

    setIsLoading(false, "");

    if (!response) {
      return;
    }

    setData(response);
  };

  useEffect(() => {
    document.title = `GAIL | ${ROUTE_LABELS.DASHBOARD}`;

    fetchData(1);
  }, []);

  const setPage = async (page: number) => {
    if (page === 0 || page === data.pagination.page) {
      return;
    }

    setData({
      ...data,
      data: null,
    });

    await fetchData(page);
  };

  return (
    <Container
      title={ROUTE_LABELS.DASHBOARD}
      subTitle={
        <>
          <span>{!user ? welcomeText : `Hello ${user.name}`}</span>
          {data.settings?.gailNumber && (
            <div className={clsx("lg:float-right", "text-sm")}>
              Your GAIL line is{" "}
              <a
                className={clsx(
                  "underline",
                  "text-blue-800",
                  "whitespace-nowrap"
                )}
                href={`tel:${data.settings.gailNumber}`}
              >
                {data.settings.gailNumber}
              </a>
            </div>
          )}
        </>
      }
    >
      {/*<DashboardHeader {...data.metrics} />*/}
      <DashboardContent
        data={data.data}
        pagination={data.pagination}
        setPage={setPage}
      />
    </Container>
  );
}
